<script>
import { WButton } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { COURSES_PROLONG_NEW_CONTENT_DATE_KEY } from '~/constants/cookies';
import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { COURSES_PROLONG_NEW_CONTENT_DATE_LS_KEY } from '~/constants/local-storage';
import { getCookie, setCookie } from '~/services/cookie-functions';
import { eventBus } from '~/services/event-bus';
import { getLSItem } from '~/services/local-storage';
import BaseLuringDot from '~/components/_base/_base-luring-dot.vue';
import CompanyLogo from '~/components/company-logo/company-logo.vue';
import MotivationNewXpIndicator from '~/components/motivation/motivation-new-xp-indicator/motivation-new-xp-indicator.vue';
import NotificationButtonPopover from '~/components/notifications/notification-button-popover.vue';
import StreaksCounterButton from '~/components/streaks/streaks-counter-button.vue';
import TheNavbarButtons from '~/components/the-navbar/the-navbar-buttons.vue';
import TheNavbarLinks from '~/components/the-navbar/the-navbar-links.vue';
import TheNavbarMenu from '~/components/the-navbar/the-navbar-menu.vue';
import TheNavbarMenuButton from '~/components/the-navbar/the-navbar-menu-button.vue';

const { mapActions, mapGetters } = createNamespacedHelpers(
  'all-student-courses'
);
const cookieMaxAge = 32 * 24 * 60 * 60;

export const messages = {
  ru: {
    home: 'На главную',
  },
};

export default {
  components: {
    BaseLuringDot,
    StreaksCounterButton,
    TheNavbarButtons,
    TheNavbarMenu,
    TheNavbarMenuButton,
    TheNavbarLinks,
    CompanyLogo,
    WButton,
    MotivationNewXpIndicator,
    NotificationButtonPopover,
  },
  data() {
    return {
      isLuringDotVisible: false,
    };
  },
  async fetch() {
    await this.fetchItems();
  },
  computed: {
    ...mapGetters(['coursesForProlongExist']),
  },
  watch: {
    $route() {
      this.hideMenu();
    },
  },
  mounted() {
    let coursesProlongNewContentDate = getCookie(
      COURSES_PROLONG_NEW_CONTENT_DATE_KEY
    );
    if (!coursesProlongNewContentDate)
      coursesProlongNewContentDate =
        this.recoverCoursesProlongNewContentDateFromLS();

    this.isLuringDotVisible =
      !coursesProlongNewContentDate ||
      new Date(coursesProlongNewContentDate) <= new Date();

    eventBus.$on(EVENT_BUS_KEYS.prolongCoursesButtonClick, () => {
      this.isLuringDotVisible = false;
    });
  },
  methods: {
    ...mapActions(['fetchItems']),
    recoverCoursesProlongNewContentDateFromLS() {
      const coursesProlongNewContentDate = getLSItem(
        COURSES_PROLONG_NEW_CONTENT_DATE_LS_KEY
      );
      if (coursesProlongNewContentDate) {
        setCookie(
          COURSES_PROLONG_NEW_CONTENT_DATE_KEY,
          coursesProlongNewContentDate,
          {
            domain: this.$config.globalCookieDomain,
            maxAge: cookieMaxAge,
            path: '/',
          }
        );
      }
      return coursesProlongNewContentDate;
    },
    showMenu() {
      const { menu } = this.$refs;
      if (menu) menu.show();
    },
    hideMenu() {
      const { menu } = this.$refs;
      if (menu) menu.hide();
    },
  },
  i18n: {
    messages,
  },
};
</script>

<template>
  <div id="the-navbar" :class="$style.root">
    <div :class="$style.content">
      <NuxtLink to="/" :aria-label="$t('home')" :class="$style.logo">
        <CompanyLogo :class="$style.logoIcon" />
      </NuxtLink>
      <div :class="$style.nav">
        <TheNavbarButtons v-if="$auth.loggedIn">
          <NotificationButtonPopover />
          <StreaksCounterButton v-if="$device.isSmallScreen" />
          <WButton
            v-if="$device.isSmallScreen"
            data-testid="burger-menu-button"
            theme="emptyLight"
            size="l"
            only-icon
            @click="showMenu"
          >
            <WIcon :class="$style.icon" name="burger-menu" />
            <BaseLuringDot
              v-if="isLuringDotVisible && coursesForProlongExist"
              :class="$style.luringDot"
            />
            <MotivationNewXpIndicator position="bottom" />
          </WButton>
        </TheNavbarButtons>
        <TheNavbarLinks v-if="!$device.isSmallScreen" :class="$style.items" />
        <TheNavbarButtons v-if="$auth.loggedIn && !$device.isSmallScreen">
          <StreaksCounterButton />
          <TheNavbarMenuButton />
        </TheNavbarButtons>
      </div>
    </div>
    <TheNavbarMenu v-if="$device.isSmallScreen" ref="menu" />
  </div>
</template>

<style lang="postcss" module>
.root {
  --padding-x: 20px;

  position: sticky;
  top: 0;
  left: 0;
  z-index: var(--the-navbar-z-index);
  height: var(--navbar-height);
  color: var(--primary-100);
  background-color: var(--primary-100);
  border-bottom: 1px solid var(--grey-300);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--max-content-width);
  height: var(--navbar-height);
  padding: 0 var(--padding-x);
  margin: 0 auto;
}

.logo {
  width: 80px;
  margin-right: 6.2%;
  padding: 8px 0;
  color: var(--primary-500);
  transition: color var(--default-transition);

  & .logoIcon {
    height: 36px;
  }
}

@media (hover: hover) {
  .logo {
    &:hover {
      color: var(--primary-200);
    }

    &:active {
      color: var(--primary-500);
    }
  }
}

.nav {
  display: flex;
  gap: 36px;
  align-items: center;
}

.icon {
  flex-shrink: 0;
  align-self: center;
  font-size: 24px;
}

.luringDot {
  position: absolute;
  margin-left: 20px;
  margin-top: -6px;
}

@media (--small-vp) {
  .nav {
    gap: 0;
    margin-right: calc(-1 * var(--padding-x));
  }
}
</style>
