import { LOCALE } from '~/constants/i18n';
import { captureExceptionWithSentry } from './utils/capture-exception-with-sentry';

const defaultToastMessageByLocale = {
  ru: 'Что-то пошло не так',
};
export const FALLBACK_DEFAULT_TOAST_MESSAGE =
  defaultToastMessageByLocale[LOCALE];

let sentry;
let toast;
let defaultToastMessage;

function init({
  $sentry,
  $toast,
  defaultToastMessage: usedDefaultToastMessage = FALLBACK_DEFAULT_TOAST_MESSAGE,
}) {
  sentry = $sentry;
  toast = $toast;
  defaultToastMessage = usedDefaultToastMessage;
}

function extractObjectValues(value) {
  let string = '';
  JSON.stringify(value, (_, value_) => {
    if (typeof value_ === 'string') {
      if (string) string += '\n';
      string += value_;
    }
    return value_;
  });
  return string;
}

function extractApiErrorMessages(apiError) {
  if (!apiError?.response?.data || typeof apiError.response.data !== 'object')
    return '';
  try {
    return extractObjectValues(apiError.response.data);
  } catch (error) {
    // eslint-disable-next-line no-use-before-define
    handleError(error);
  }

  return '';
}

function handleError(
  error,
  { captureError = true, showToast = true, toastMessage } = {}
) {
  if (showToast && !process.server) {
    const statusCode = error?.response?.status;
    let errorMessage = toastMessage || defaultToastMessage;
    if (statusCode === 400) {
      errorMessage =
        toastMessage || extractApiErrorMessages(error) || defaultToastMessage;
    }

    toast.error(errorMessage);
  }

  if (captureError) captureExceptionWithSentry({ sentry, error });
}

export { init, handleError, extractApiErrorMessages };
