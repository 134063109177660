let nuxtInstance;

export const init = (usedNuxtInstance) => {
  nuxtInstance = usedNuxtInstance;
};

export function setCookie(name, value, options) {
  /* eslint-disable-next-line no-restricted-properties */
  return nuxtInstance.$cookies.set(name, value, options);
}

export function getCookie(name, options) {
  /* eslint-disable-next-line no-restricted-properties */
  return nuxtInstance.$cookies.get(name, options);
}

export function removeCookie(name, options) {
  /* eslint-disable-next-line no-restricted-properties */
  return nuxtInstance.$cookies.remove(name, options);
}
