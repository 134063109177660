<script>
import { WButton } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { COURSES_PROLONG_NEW_CONTENT_DATE_KEY } from '~/constants/cookies';
import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { getCookie, setCookie } from '~/services/cookie-functions';
import { dateForProlong } from '~/services/course';
import { eventBus } from '~/services/event-bus';
import { buildCartUrl } from '~/services/shop';
import BaseLuringDot from '~/components/_base/_base-luring-dot.vue';

const { mapActions, mapGetters } = createNamespacedHelpers(
  'all-student-courses'
);
const cookieMaxAge = 32 * 24 * 60 * 60;

export const messages = {
  ru: {
    prolongCourses: 'продлить курс | продлить курс | продлить курсы',
  },
};

export default {
  i18n: { messages },
  components: {
    WButton,
    BaseLuringDot,
  },
  data() {
    return {
      isLuringDotVisible: false,
    };
  },
  async fetch() {
    if (!this.isEmpty) return;
    await this.fetchItems();
  },
  computed: {
    ...mapGetters(['coursesForProlong', 'coursesForProlongExist', 'isEmpty']),
  },
  mounted() {
    const coursesProlongNewContentDate = getCookie(
      COURSES_PROLONG_NEW_CONTENT_DATE_KEY
    );

    this.isLuringDotVisible =
      !coursesProlongNewContentDate ||
      new Date(coursesProlongNewContentDate) <= new Date();
  },
  methods: {
    ...mapActions(['fetchItems']),
    onClick() {
      setCookie(COURSES_PROLONG_NEW_CONTENT_DATE_KEY, dateForProlong(), {
        domain: this.$config.globalCookieDomain,
        maxAge: cookieMaxAge,
        path: '/',
      });

      eventBus.$emit(EVENT_BUS_KEYS.prolongCoursesButtonClick);
      this.isLuringDotVisible = false;
      window.open(buildCartUrl(this.coursesForProlong));
    },
  },
};
</script>

<template>
  <WButton
    v-if="coursesForProlongExist"
    :class="$style.root"
    theme="emptyLight"
    @click="onClick"
  >
    <WIcon :class="$style.icon" name="extend" />
    {{ $tc('prolongCourses', coursesForProlong.length) }}
    <BaseLuringDot v-if="isLuringDotVisible" :class="$style.luringDot" />
  </WButton>
</template>

<style lang="postcss" module>
.root {
  position: relative;
}

.icon {
  flex-shrink: 0;
  font-size: 24px;
}

.luringDot {
  margin-left: -6px;
  margin-top: 4px;
}

@media (--small-vp) {
  .luringDot {
    position: static;
    top: auto;
    right: auto;
    align-self: center;
    transform: none;
  }
}
</style>
